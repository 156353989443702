<template>
  <div>
    <v-row>
      <v-col>
        <p class="text-center text-body-1">
          Herzlich willkommen in der Eifel und in unserer wunderschönen Wacholderhütte.
        </p>
      </v-col>
    </v-row>
    <MenuDownloadRow/>
    <v-row v-if="false">
      <v-col>
        <v-alert outlined color="#98173E" >
          <div class="title">
            Betriebsferien im Juli
          </div>
          <div class="pt-5">
            Liebe Gäste, <br/>
            <br/>
            wir haben vom 22.07. bis einschließlich 30.07. geschlossen.
            <br/>
            Ab dem 31.07. freuen wir uns Sie wieder bei uns begrüßen zu dürfen.
            <br/>
            <br/>
            Mit freundlichen Grüßen,<br/>
            Ihr Team der Wacholderhütte<br/>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-img :eager="true" :src="require('../assets/wacholderhuette_sommer.jpg')" width="100%">
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MenuDownloadRow from "@/components/MenuDownloadRow.vue";

export default {
  name: "WelcomeBlock",
  components: {MenuDownloadRow},
  props: {
    menuShown: Boolean
  },
  computed: {
    week() {
      return new Date().getWeekNumber()
    }
  }
}
</script>

<style scoped lang="sass">

</style>